interface AutocompleteApiResponseItem {
    Result: string;
    Type: string;
    Url: string;
}

export interface AutocompleteSearchResponseItem {
    label: string;
    category: string;
    class?: string;
    url: string;
}

function getSearchUrl(request: string) {
    return "/api/partautocomplete?query=" + encodeURIComponent(request.trim());
}

export const useSearch = () => {
    const { locale } = useI18n();

    return {
        getSearchResults: async (request: string): Promise<AutocompleteSearchResponseItem[]> => {
            let items = [];

            const response = await axios.get(getSearchUrl(request));
            if (response) {
                items = response.data.map((x: AutocompleteApiResponseItem) => {
                    return {
                        label: x.Result,
                        category: x.Type,
                        url: x.Url,
                    };
                });
            }

            return items;
        },

        execute: (searchTerm: MaybeRefOrGetter<string>) => {
            const searchValue = toValue(searchTerm);

            // Ignore non-word characters for min length comparison to make sure we really have something to work with.
            if (searchValue.replace(/[\W_]/g, "").trim().length < 3) {
                return;
            }

            const cleanedValue = searchValue
                .replace(/[><]/g, "") // Remove angle brackets since they are considered unsafe for our URLs.
                .replace(/\t/g, " ") // Replace tabs with spaces since they break the server
                .trim();

            const searchUrl = `/${locale.value}/search/${encodeURIComponent(cleanedValue)}`;
            return navigateTo(searchUrl, { external: true });
        },
    };
};
